import React, { useContext, useEffect } from "react"
import dayjs from "dayjs"
import { useLocation } from "@reach/router"
import SimpleLayout from "../components/layouts/SimpleLayout"
import SEO from "../components/seo"
import OrderSummary from "../components/checkout/OrderSummary"
import { useCart } from "../components/common/hooks/useCart"
import { CartContext } from "../context/CartContext"

const Success = () => {
  const location = useLocation()
  const cartId = location.search.split("=")[1]
  const { cart } = useCart(cartId)
  const { updateCartId } = useContext(CartContext)

  //Remove the cart id from localstorage so the next order has a different id
  useEffect(() => {
    updateCartId("")
  }, [updateCartId])

  return (
    <SimpleLayout>
      <SEO pageTitle="Checkout" ogTitle="Checkout" pathname="/checkout" />
      <div className="max-w-6xl p-4 mx-auto bg-white md:mt-16 md:mb-16 md:p-8">
        <div className="grid grid-cols-1 gap-16 sm:grid-cols-2 lg:grid-cols-3">
          <div className="lg:col-span-2">
            <h1 className="mb-8 text-3xl font-thin text-gray-900">
              Thank you for your support!
            </h1>
            <h2 className="mb-4 text-2xl text-indigo-800">Order Details</h2>
            <dl className="grid max-w-sm grid-cols-2 gap-4">
              <dt className="mr-16 font-bold">Order Date</dt>
              <dd>
                {cart && dayjs(cart.orderDate).format("MM/DD/YYYY h:mm a")}
              </dd>
              <dt className="font-bold">Order Id</dt>
              <dd>{cart && cart.id}</dd>
              <dt className="font-bold">Your Information</dt>
              <dd>
                <div>{cart && cart.shipping.name}</div>
                <div>{cart && cart.shipping.email}</div>
              </dd>
            </dl>
          </div>
          <OrderSummary order={cart} />
        </div>
      </div>
    </SimpleLayout>
  )
}

export default Success
