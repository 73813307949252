import React from "react"
import { formatPrice } from "../../utils/currency.util"
import PageLoader from "../common/loaders/PageLoader"
import Img from "gatsby-image"

const OrderSummary = ({ order }) => {
  if (!order) {
    return (
      <aside>
        <div className="flex items-center justify-center px-32 py-8">
          <PageLoader size={20} />
        </div>
      </aside>
    )
  }
  const subtotal = order.items.reduce((total, item) => {
    return total + item.price;
  }, 0);

  return (
    <aside>
      <div className="px-4 py-8 border">
        <h2 className="text-lg font-medium leading-6 text-gray-900">
          Order Summary
        </h2>
        {order.items.map((item) => (
          <div className="grid grid-cols-4 gap-4 mt-6" key={item.sku}>
            <Img className="w-16 h-16" fluid={item.image.asset.fluid} alt={item.title}/>
            <div className="col-span-2">
              <h3 className="text-sm font-bold text-gray-900">
                {item.title}
              </h3>
              <p className="text-xs font-thin text-gray-900">
                Quantity: {item.quantity}
              </p>
              {item.options.map((option) => (
                <p
                  className="text-xs font-thin text-gray-900"
                  key={option.label}
                >{`${option.label}: ${option.selectedValue}`}</p>
              ))}
            </div>
            <div>
              <p className="text-sm font-medium text-gray-900">
                {formatPrice(item.price * item.quantity)}
              </p>
            </div>
          </div>
        ))}
        <div className="flex justify-between pt-4 mt-6 border-t">
          <p className="text-sm font-thin text-gray-900">Subtotal:</p>
          <p className="text-sm font-bold text-gray-900">
            {formatPrice(subtotal)}
          </p>
        </div>
        <div className="flex justify-between pt-2">
          <p className="text-sm font-thin text-gray-900">Shipping:</p>
          <p className="text-sm font-medium text-gray-900">${order.shipping.shippingPrice}</p>
        </div>
        <div className="flex justify-between pt-2 mt-6 border-t">
          <p className="text-lg font-thin text-gray-900">Total:</p>
          <p className="text-lg font-bold text-indigo-600">
            ${(Number(subtotal) + Number(order.shipping.shippingPrice)).toFixed(2)}
          </p>
        </div>
      </div>
    </aside>
  )
}

export default OrderSummary
