import axios from "axios"

const createCart = async (item, campaignCode) => {
  const response = await axios.post(`/api/cart`, {
    campaignCode,
    items: [item],
  })
  return response.data
}

export const fetchCart = async (cartId) => {
  const response = await axios.get(`/api/cart/${cartId}`)
  return response.data
}

export const updateQuantity = async ({ cartId, item, quantity }) => {
  const request = {
    ...item,
    quantity,
  }
  const response = await axios.put(
    `/api/cart/${cartId}/items/${item.sku}`,
    request
  )
  return response.data
}

export const updateItem = async (cartId, item) => {
  const response = await axios.put(
    `/api/cart/${cartId}/items/${item.sku}`,
    item
  )
  return response.data
}

export const postItem = async (cartId, item) => {
  const response = await axios.post(`/api/cart/${cartId}/items`, [item])
  return response.data
}

export const deleteItem = async (cartId, itemId) => {
  const response = await axios.delete(`/api/cart/${cartId}/items/${itemId}`)
  return await response.data
}

export const addItemToCart = async (cartId, product, campaignCode) => {
  const item = {
    quantity: 1,
    ...product,
    options: product.options.map((option) => ({
      ...option,
      selectedValue: option.possibleValues[0],
    })),
  }
  //If no cart id, create cart, add item and then we can be done
  if (!cartId) {
    const cart = await createCart(item, campaignCode)
    return cart.id
  }

  //If cart id, fetch Cart to make sure we are dealing with "fresh" data
  const cart = await fetchCart(cartId)

  const existingItem = cart.items.find(
    (cartItem) => cartItem.sku === product.sku
  )

  //If item does exist, update quantity by one
  if (existingItem) {
    await updateQuantity({
      cartId: cart.id,
      item: existingItem,
      quantity: Number(existingItem.quantity) + 1,
    })
    return cart.id
  }

  //If item doesn't exist in cart, add it
  await postItem(cart.id, item)

  return cart.id
}

export const addShippingToOrder = async (cartId, shipping) => {
  const response = await axios.post(`/api/cart/${cartId}/shipping`, shipping)
  return response.data
}
