import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Navbar = () => {
  const logo = useStaticQuery(graphql`
    query {
      sanityHome(title: { eq: "Landing" }) {
        headerLogo {
          asset {
            fluid(maxWidth: 300) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  `)

  return (
    <nav className="flex bg-indigo-800">
      <div className="flex p-6">
        <Link to="/">
          <Img
            className="w-40"
            fluid={logo.sanityHome.headerLogo.asset.fluid}
            alt="Fundraisify logo"
          />
        </Link>
      </div>
    </nav>
  )
}

export default Navbar
