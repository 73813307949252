import React from "react"
import Navbar from "../common/Navbar"
import Footer from "../common/Footer"

const SimpleLayout = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen md:bg-gray-100">
      <Navbar />
      <main className="flex-grow">{children}</main>
      <Footer />
    </div>
  )
}

export default SimpleLayout
