import { useState, useEffect, useContext } from "react"
import { loadStripe } from "@stripe/stripe-js"
import { CartContext } from "../../../context/CartContext"
import {
  fetchCart,
  deleteItem,
  updateQuantity as updateServerQuantity,
  updateItem,
  addShippingToOrder,
  addItemToCart,
} from "../../../utils/cart.util"

//TODO: make these environment specific
const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLIC_KEY)
const DEFAULT_SHIPPING_PRICE = process.env.GATSBY_STRIPE_DEFAULT_SHIPPING

export const useCart = (cartId) => {
  const [cart, setCart] = useState()
  const [status, setStatus] = useState("INITIALIZED")
  const [errorMessage, setErrorMessage] = useState(
    "An unexpected error occurred."
  )
  const { cartId: contextCartId, updateCartId } = useContext(CartContext)

  if (!cartId) {
    cartId = contextCartId
  }

  useEffect(() => {
    const fetchCartFromServer = async () => {
      setStatus("LOADING")
      if (!cartId) {
        setStatus("EMPTY_CART")
        return
      }
      try {
        const cart = await fetchCart(cartId)
        setCart(cart)
        setStatus("SUCCESS")
      } catch (e) {
        console.error("Unable to fetch cart, rendering empty cart", e)
        setStatus("EMPTY_CART")
        updateCartId("")
      }
    }
    fetchCartFromServer()
  }, [cartId, updateCartId])

  const updateQuantity = async (item, quantity) => {
    //Update UI right away so the field doesn't lag
    const updatedItems = [...cart.items]
    for (let updatedItem of updatedItems) {
      if (updatedItem.sku === item.sku) {
        updatedItem.quantity = quantity
      }
    }
    cart.items = updatedItems
    setCart(cart)

    //In the background, update the cart
    if (quantity && quantity > 0) {
      setStatus("LOADING_TOTAL")
      try {
        const updatedCart = await updateServerQuantity({
          cartId,
          item,
          quantity,
        })
        setCart(updatedCart)
        setStatus("SUCCESS")
      } catch (e) {
        setErrorMessage("Unable to update quantity. Please try again.")
        setStatus("ERROR")
        console.error("Unable to update quantity", e)
      }
    }
  }

  const updateCustomField = async (item, customField) => {
    const updatedOptions = item.options.map((option) => {
      if (option.key === customField.key) {
        return {
          ...option,
          selectedValue: customField.selectedValue,
        }
      }
      return option
    })
    const updatedItem = {
      ...item,
      options: updatedOptions,
    }
    try {
      const updatedCart = await updateItem(cart.id, updatedItem)
      setCart(updatedCart)
    } catch (e) {
      setErrorMessage("Unable to update field. Please try again.")
      setStatus("ERROR")
      console.error("Unable to update custom field", e)
    }
  }

  const removeItem = async (item) => {
    const isLastItem = cart.items.length === 1
    if (isLastItem) {
      setStatus("LOADING")
    } else {
      setStatus("LOADING_TOTAL")
    }

    const updatedCart = await deleteItem(cart.id, item.sku)

    setCart(updatedCart)

    if (isLastItem) {
      setStatus("EMPTY_CART")
    } else {
      setStatus("SUCCESS")
    }
  }

  const findMatchingShippingTier = (campaign) => {
    const totalNumberOfItems = cart.items.reduce(
      (previous, item) => previous + Number(item.quantity),
      0
    )

    if (campaign) {
      const { shippingTiers } = campaign.node.vendor
      return shippingTiers.find(
        (shippingTier) => Number(shippingTier.quantity) === totalNumberOfItems
      )
    }
  }

  const calculateShippingPrice = (campaign) => {
    const matchingShippingTier = findMatchingShippingTier(campaign)
    if (matchingShippingTier) {
      return matchingShippingTier.stripeReferenceId
    }
    return DEFAULT_SHIPPING_PRICE
  }

  const addItem = async (product, campaign) => {
    const updatedCartId = await addItemToCart(cartId, product, campaign)
    updateCartId(updatedCartId)
  }

  const handleCheckout = async (campaign) => {
    setStatus("LOADING_CHECKOUT")
    try {
      const stripe = await stripePromise
      const shipping = {
        ...findMatchingShippingTier(campaign),
      }
      await addShippingToOrder(cart.id, shipping)
      await stripe.redirectToCheckout({
        lineItems: cart.items
          .map((item) => ({
            price: item.stripeReferenceId,
            quantity: Number(item.quantity),
          }))
          .concat([
            {
              price: calculateShippingPrice(campaign),
              quantity: 1,
            },
          ]),
        mode: "payment",
        successUrl: `${window.location.origin}/success?order=${cartId}`,
        cancelUrl: `${window.location.origin}/cart`,
        shippingAddressCollection: {
          allowedCountries: ["US"],
        },
        clientReferenceId: cart.id,
      })
      setStatus("SUCCESS")
    } catch (e) {
      console.error("Unable to redirect to checkout.", e)
      setErrorMessage("Unable to redirect to checkout. Please try again.")
      setStatus("ERROR")
    }
  }

  return {
    cart,
    status,
    errorMessage,
    updateQuantity,
    updateCustomField,
    addItem,
    removeItem,
    handleCheckout,
  }
}
