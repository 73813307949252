export const formatPrice = (amount) => {
  if (!amount) {
    return Number(0).toFixed(2)
  }
  let price = amount.toFixed(2)
  let numberFormat = new Intl.NumberFormat(["en-US"], {
    style: "currency",
    currency: "USD",
    currencyDisplay: "symbol",
  })
  return numberFormat.format(price)
}
