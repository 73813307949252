import React from "react"
import "./page-loader.css"

const PageLoader = ({ size = 16 }) => (
  <svg
    viewBox="25 25 50 50"
    className={`w-${size} text-indigo-500 page-loader`}
  >
    <circle cx="50" cy="50" r="20"></circle>
  </svg>
)

export default PageLoader
